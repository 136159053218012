import { deepClone } from "@ax/helpers";
import { IStructuredDataContent, IStructuredData, ISchemaField, IStructuredDataCategory } from "@ax/types";

const prepareStructuredDataContent = (structuredDataContent: IStructuredDataContent | IStructuredDataCategory) => {
  return { ...structuredDataContent, content: structuredDataContent.content };
};

const updateCurrentDataContent = (getState: any, data: IStructuredDataContent) => {
  const {
    structuredData: { currentDataContent },
  } = getState();

  return currentDataContent.map((item: IStructuredDataContent) => {
    if (item.id === data.id) {
      item = data;
    }
    return item;
  });
};

const generateForm = (schema: any) => {
  const { fields } = schema;
  return fields.reduce((obj: Record<string, unknown>, field: any) => {
    return {
      ...obj,
      [field.key]: "",
    };
  }, {});
};

const getTaxonomies = (data: IStructuredData[]) => data.filter((item: IStructuredData) => item.taxonomy);
const getTypes = (data: IStructuredData[]) => data.filter((item: IStructuredData) => !item.taxonomy);
const filterStructuredDataByID = (data: any, id: string) => data.find((item: any) => item.id === id);

const evalueDataComputedFields = (structuredData: any, fields: any[]) => {
  const updatedData = deepClone(structuredData);
  fields.forEach((field: ISchemaField) => {
    if (field.hasOwnProperty("computed")) {
      const computedFunction = eval(`(${field.computed})`);
      updatedData.content[field.key] = computedFunction(structuredData.content);
    }
  });
  return updatedData;
};

export {
  prepareStructuredDataContent,
  updateCurrentDataContent,
  generateForm,
  getTaxonomies,
  getTypes,
  filterStructuredDataByID,
  evalueDataComputedFields,
};
