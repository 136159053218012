import type { ImageDecoding, ImageLoading } from "../types/core";

import { useSite } from "./useSite";
import {
	cleanCloudinaryURI,
	cloudinaryRearrangeResponsiveArray,
	removeUnit,
} from "../functions/image-utils";

const internalDefaults: CloudinaryImageConfig = {
	domain: "https://res.cloudinary.com",
	uploadFolder: "image/upload",
	fallback404ImageId: "dx-placeholders/fallback-404-image",
	quality: 51,
	crop: "fill",
	gravity: "center",
	format: "auto",
	loading: "lazy",
	backgroundLazy: false,
	decoding: "auto",
	responsive: [{ breakpoint: null, width: "320px" }],
};

/**
 * `useImage()`
 *
 * @deprecated This hook will be removed from Griddo in the future.
 */
function useImage({ publicId, ...props }: UseImageProps) {
	const { cloudinaryCloudName, cloudinaryDefaults } = useSite();

	const imageConfig = {
		...internalDefaults,
		...cloudinaryDefaults,
		...props,
	};

	const root = `${imageConfig.domain}/${cloudinaryCloudName}/${imageConfig.uploadFolder}`;

	// 404 Fallback image
	// TODO: Create a Cloudinary query to create an image on the fly.
	const fallbackSrcImage = `${getURIWithParams({
		root,
		imageConfig: {
			...imageConfig,
			quality: 20,
			width: "512",
		},
	})}/${imageConfig.fallback404ImageId}`;

	// Create the content of the srcSet <img> attribute
	const srcSet = imageConfig?.responsive?.map(
		({ width, height, quality, crop }) => {
			const cloudinaryURI = getURIWithParams({
				root,
				imageConfig: {
					quality,
					crop,
					width,
					height,
					...imageConfig,
				},
			});

			return `${cloudinaryURI}/${publicId} ${removeUnit(width)}w`;
		},
	);

	// Create the content of the sizes <img> attribute
	// rearange put the element of the array with the breakpoint === null and
	// put it at the last position to use properly in the mediaquery interpolation
	const sizes = cloudinaryRearrangeResponsiveArray(
		imageConfig?.responsive?.reverse(),
	)
		.map((query, idx) => {
			if (imageConfig.responsive && idx < imageConfig?.responsive?.length - 1) {
				return `(min-width: ${query.breakpoint}) ${query.width}`;
			}

			return `${query.width}`;
		})
		.join(", ");

	// Create a kind of srcSet for background images (removing the final size xxxw)
	const srcSetURL = srcSet?.map((entry) => entry.split(" ")[0]);

	// Get the src from the first (usually the small) breakpoint
	const smallForSrc =
		(imageConfig?.responsive && imageConfig?.responsive[0]) || [];

	// Old browsers src fallback (in case it doesn't support srcSet)
	const src = `${getURIWithParams({
		root,
		imageConfig,
		...smallForSrc,
	})}/${publicId}`;

	return {
		src,
		srcSet,
		sizes,
		fallbackSrcImage,
		srcSetURL,
	};
}

function getURIWithParams(props: GetURIWithParamsProps) {
	const { root, imageConfig } = props;

	const crop = imageConfig?.crop ? `c_${imageConfig.crop}` : "";
	const quality = imageConfig?.quality ? `q_${imageConfig.quality}` : "";
	const gravity = imageConfig?.gravity ? `g_${imageConfig.gravity}` : "";
	const format = imageConfig?.format ? `f_${imageConfig.format}` : "";
	const width = imageConfig?.width ? `w_${removeUnit(imageConfig.width)}` : "";
	const height = imageConfig?.height
		? `h_${removeUnit(imageConfig.height)}`
		: "";
	const aspectRatio = imageConfig?.ar ? `ar_${imageConfig.ar}` : "";

	// Clean the URI to avoid orphans query params: w_null, w_, etc..
	const queryParamsURL = cleanCloudinaryURI(
		`${crop},${gravity},${format},${quality},${width},${height},${aspectRatio}`,
	);

	const url = `${root}/${queryParamsURL}`;

	return url;
}

interface GetURIWithParamsProps {
	root?: string;
	imageConfig?: CloudinaryImageConfig;
}

export type UseImageProps = CloudinaryImageConfig;

interface CloudinaryImageConfig {
	responsive?: Array<CloudinaryResponsiveImageProps>;
	ar?: number | string;
	backgroundLazy?: boolean;
	crop?: "fill" | "crop" | "thumb" | "fill_pad" | "lfill";
	decoding?: ImageDecoding;
	domain?: string;
	fallback404Image?: string;
	fallback404ImageId?: string;
	format?: "auto" | "jpg" | "webp" | "avif" | "gif" | "png";
	gravity?: string;
	height?: string;
	loading?: ImageLoading;
	publicId?: string;
	quality?: number;
	sizes?: string;
	uploadFolder?: string;
	width?: string;
}

export interface CloudinaryResponsiveImageProps {
	breakpoint?: null | string;
	width?: string;
	height?: string;
	quality?: number;
	crop?: CloudinaryImageCrop;
	format?: CloudinaryImageFormat;
}

export type CloudinaryImageFormat =
	| "auto"
	| "jpg"
	| "webp"
	| "avif"
	| "gif"
	| "png";

export type CloudinaryImageCrop =
	| "fill"
	| "crop"
	| "thumb"
	| "fill_pad"
	| "lfill";

export { useImage };
