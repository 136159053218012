import type { Url } from "../../types/api-response-fields";

import * as React from "react";

import { getLinks, parseAnchor } from "../../functions/utils";
import { useLink } from "../../hooks/useLink";
import { usePage } from "../../hooks/usePage";

/**
 * @deprecated This component will be removed from Griddo in the future.
 */
function Link(props: LinkProps) {
	const { url, linkProp = "to", children, ...rest } = props;

	// Get page info from provider
	const { fullUrl } = usePage();
	// Get the router component from the provider
	const RouterLink = useLink() || "a";
	// Get href for the normal links and to for the router links
	const { href, to } = getLinks(url);

	const newTab = url?.newTab;
	const noFollow = url?.noFollow;

	const linkProps = {
		...rest,
		...(newTab && { target: "_blank" }),
		rel: `${noFollow ? "nofollow" : ""} ${newTab ? "noreferrer" : ""}`.trim(),
		...(fullUrl && href && { href: parseAnchor(fullUrl, href) }),
		...(fullUrl && to && { [linkProp]: parseAnchor(fullUrl, to) }),
	};

	return to ? (
		<>
			<p>Router</p>
			<RouterLink {...linkProps}>{children}</RouterLink>
		</>
	) : (
		React.createElement("a", linkProps, children)
	);
}

interface LinkProps {
	url: Url;
	linkProp?: string;
	children: JSX.Element;
	[key: string]: unknown;
}

export { Link };
