export default {
	domain: "https://res.cloudinary.com",
	uploadFolder: "image/upload",
	fallback404ImageId: "dx-placeholders/fallback-404-image",
	quality: 51,
	crop: "fill",
	gravity: "faces:center",
	format: "auto",
	loading: "lazy",
	backgroundLazy: false,
	decoding: "auto",
	responsive: [{ breakpoint: null, width: "320px" }],
};
